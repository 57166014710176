import cn from 'classnames'

import {useRouter} from 'next/router'

import {useEffect, useState} from 'react'

import styles from './Header.module.scss'

import MenuToggle from '@/components/atoms/MenuToggle/MenuToggle'
import Navigation from '@/components/molecules/Navigation'
import {MenuItem} from '@/components/types/types'
import ScrollDirection from '@/functions/ScrollDirection'
import {
  disableScroll,
  enableScroll
} from '../../../functions/enableDisableScroll'
type Props = {
  menu: MenuItem[]
  menuSocial: MenuItem[]
}

export default function Header({menu, menuSocial}: Props) {
  const [menuOpen, setMenuOpen] = useState(false)

  const [backgroundHeader, setBackgroundHeader] = useState(false)

  const router = useRouter()

  useEffect(() => {
    if (menuOpen) {
      disableScroll()
    } else {
      enableScroll(true)
    }
  }, [menuOpen])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [router.asPath])

  const scrollDirection = ScrollDirection()

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset > 10 && !menuOpen) {
        setBackgroundHeader(true)
      } else {
        setBackgroundHeader(false)
      }
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDirection])

  return (
    <header className={cn(styles.header, menuOpen ? styles.menuOpen : '')}>
      <div className={styles.navigationWrapper}>
        <div
          className={cn(
            styles.navigation,
            backgroundHeader ? styles.backgroundHeader : ''
          )}
        >
          <MenuToggle menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
      </div>
      <div className={styles.headerWrapper}>
        <div
          className={cn(styles.headerContainer, menuOpen ? styles.isOpen : '')}
        >
          <Navigation menu={menu} menuOpen={menuOpen} menuSocial={menuSocial} />
        </div>
      </div>
    </header>
  )
}
