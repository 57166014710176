export const disableScroll = () => {
  document.body.style.top = `-${window.pageYOffset}px`
  document.body.classList.add('no-scroll')
}

export const enableScroll = (keepPosition) => {
  const windowTop = document.body.style.top
  document.body.classList.remove('no-scroll')
  if (windowTop.length > 0 && keepPosition)
    window.scrollTo(0, windowTop.match(/\d+/)[0])
}
