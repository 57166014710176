import Button from '@/components/atoms/Button'
import isLinkActive from '@/functions/isLinkActive'
import useMediaQuery from '@/functions/useMediaQuery'
import cn from 'classnames'
import {lg} from 'config'
import Link from 'next/link'
import {useRouter} from 'next/router'
import PropTypes from 'prop-types'
import NavigationMenu from '../NavigationMenu/NavigationMenu'
import styles from './NavigationItem.module.scss'

/**
 *
 * @param  props0
 * @param  props0.item
 */
export default function NavigationItem({item, animationDelay, menuOpen}) {
  const {asPath} = useRouter() ?? {}

  const isLg = useMediaQuery(lg)

  const children =
    item.children && item.children.length > 0 ? item.children : ''

  return (
    <li
      className={cn(
        children && children?.length ? styles.hasChildren : '',
        item.cssClasses.includes('is--button') && styles.hasButton,
        styles.item,
        menuOpen ? styles.activeAniamtion : ''
      )}
      style={{transitionDelay: `${animationDelay}s`}}
    >
      {item.cssClasses.includes('is--button') && isLg ? (
        <Button url={item.path} text={item.label} type="secondary" />
      ) : (
        <>
          <Link href={item.path}>
            <a
              target={item.target ? item.target : '_self'}
              className={cn(
                isLinkActive(asPath, item.path) && styles.active,
                styles.link
              )}
            >
              {item.label}
            </a>
          </Link>
        </>
      )}
      {!!children && !!children.length && (
        <ul className={styles.subMenu}>
          <NavigationMenu
            menu={children}
            menuOpen={menuOpen}
            isSubMenu={true}
          />
        </ul>
      )}
    </li>
  )
}

NavigationItem.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object),
  animationDelay: PropTypes.number,
  menuOpen: PropTypes.bool,
  item: PropTypes.shape({
    path: PropTypes.string,
    target: PropTypes.string,
    label: PropTypes.string,
    cssClasses: PropTypes.arrayOf(PropTypes.string)
  })
}
