import cn from 'classnames'

import Image from 'next/image'

import PropTypes from 'prop-types'

import {useEffect, useState} from 'react'

import Container from '../atoms/Container'

import Footer from '../organisms/Footer/Footer'

import styles from './Layout.module.scss'

import TeaserLogo from '@/components/atoms/TeaserLogo/TeaserLogo'

import Meta from '@/components/common/Meta'

import {useWordPressContext} from '@/components/common/WordPressProvider'

import Header from '@/components/organisms/Header'

import Icon from '@/components/atoms/Icon'

import Teaser from '@/components/blocks/custom/Teaser'

import {cookieNameFunctional} from '@/config'

import {seoPropTypes} from '@/functions/getPagePropTypes'

import {useRouter} from 'next/router'

import UniversalCookies from 'universal-cookie'

import CookieBanner from '../blocks/custom/CookieBanner/CookieBanner'

import Cookies from '../blocks/custom/Cookies/Cookies'
/**
 * Render the Layout component.
 *
 * @author WebDevStudios
 * @param  {object}  props          The component attributes as props.
 * @param  {any}     props.children Child component(s) to render.
 * @param  {object}  props.seo      Yoast SEO data from WordPress.
 * @return {Element}                The Layout component.
 */

export default function Layout({children, seo}) {
  const {menus, post} = useWordPressContext()
  const cookies = new UniversalCookies()
  const [showTransition, setShowTransition] = useState(false)

  const cookieValue = cookies.get(cookieNameFunctional)

  const {locale: activeLocale, asPath} = useRouter()

  useEffect(() => {
    if (asPath.includes('#')) {
      const id = asPath.split('#')[1]
      setTimeout(() => {
        const element = document.getElementById(id)

        if (element) {
          element.scrollIntoView({behavior: 'smooth'})
        }
      }, 10)
    }
  }, [asPath])

  useEffect(() => {
    setShowTransition(true)
  }, [])

  const menu =
    activeLocale === 'en' ? menus?.primary_menu___en : menus?.primary_menu
  const footerMenu =
    activeLocale === 'en' ? menus?.footer_menu___en : menus?.footer_menu
  const legalMenu = activeLocale === 'en' ? menus?.legal___en : menus?.legal

  return (
    <>
      <Meta seo={seo} post={post} />
      {showTransition && <Header menu={menu} menuSocial={menus?.social_menu} />}

      {post?.isFrontPage && (
        <Container removePaddingMobile removeLeftBorder>
          <div className={styles.teaser}>
            <div className={styles.backgroundContainer}>
              <div className={styles.imageBackground}>
                {post?.featuredImage?.node && (
                  <Image
                    data-aos="fade-in"
                    src={post.featuredImage.node.mediaItemUrl}
                    alt={post.featuredImage.node.altText}
                    layout="responsive"
                    width={post.featuredImage.node.mediaDetails.width}
                    height={post.featuredImage.node.mediaDetails.height}
                    objectFit="cover"
                    className={styles.image}
                  />
                )}
                <TeaserLogo
                  className={styles.teaserLogo}
                  activeLocale={activeLocale}
                />
              </div>
            </div>
            <div data-aos="fade-in" className={styles.teaserIcon}>
              <Icon icon="arrowDown" />
            </div>
          </div>
        </Container>
      )}

      {showTransition && <Cookies />}
      {!cookieValue && showTransition && <CookieBanner />}

      <main className={cn(styles.main)}>
        {post?.template?.templateName === 'Page Static' ? (
          <>
            <Container>
              <Teaser block={{title: post?.title}} />
              <div className={styles.pageStaticWrapper}>{children}</div>
            </Container>
          </>
        ) : (
          children
        )}
      </main>
      <Footer
        social={seo?.social}
        siteTitle={seo?.siteTitle}
        menu={footerMenu}
        menuLegal={legalMenu}
        menuSocial={menus?.social_menu}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  paddingTop: PropTypes.oneOf(['sm', 'md']),
  ...seoPropTypes
}

Layout.defaultProps = {
  paddingTop: 'md'
}
