import Container from '@/components/atoms/Container/Container'
import Icon from '@/components/atoms/Icon'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import cn from 'classnames'
import Image from 'next/image'
import {useRouter} from 'next/router'
import styles from './Teaser.module.scss'

type TeaserProps = {
  block: {
    title: string
    subtitle?: string
    label?: string
  }
}

export default function Teaser({block}: TeaserProps) {
  const {title, subtitle, label} = block
  const {post}: any = useWordPressContext()
  const router = useRouter()

  return (
    <div className={styles.teaser}>
      <Container removeBorders removePaddingMobile>
        <div className={styles.teaserWrapper}>
          <div className={styles.teaserContainer}>
            <div className={styles.teaserBar}>
              <div
                onClick={() => router.push('/')}
                className={cn(styles.label, styles.logo)}
              >
                {router.locale === 'en' ? 'DHZB Foundation' : 'DHZB Stiftung'}
              </div>
              {label && <div className={styles.label}>{label}</div>}
            </div>
            <div
              className={cn(
                styles.textWrapper,
                post.featuredImage?.node?.mediaItemUrl
                  ? styles.hasSamllerMarginBottom
                  : ''
              )}
            >
              <div data-aos="fade-up" className={styles.title}>
                {title}
              </div>
              {subtitle && (
                <div data-aos="fade-up" className={styles.subtitle}>
                  {subtitle}
                </div>
              )}
            </div>
            {post.featuredImage?.node?.mediaItemUrl && (
              <div className={styles.imageWrapper}>
                <Image
                  src={post.featuredImage?.node?.mediaItemUrl}
                  alt={post.featuredImage?.node?.altText}
                  width={post.featuredImage?.node?.mediaDetails.width}
                  height={post.featuredImage?.node?.mediaDetails.height}
                  layout="responsive"
                  objectFit="cover"
                  data-aos="fade-in"
                />
              </div>
            )}
          </div>
          <div className={styles.teaserIcon}>
            <Icon icon="arrowDown" />
          </div>
        </div>
      </Container>
    </div>
  )
}
