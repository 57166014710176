import Button from '@/components/atoms/Button'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import {
  cookieNameFunctional,
  cookieNameStatistics,
  cookieSettings
} from '@/config'
import cn from 'classnames'
import {useContext, useEffect, useState} from 'react'
import CookiesObject from 'universal-cookie'
import {enableScroll} from '../../../../functions/enableDisableScroll'
import Icon from '../../../atoms/Icon/Icon'
import CookieHelper from './CookieHelper'
import styles from './Cookies.module.scss'
import {CookiesFormContext} from './CookiesFormContext'
const Cookies = () => {
  const {
    themeOptions: {
      cookies,
      cookieBannerDescription,
      cookiesTitle,
      acceptCookiesLabel,
      rejectCookiesLabel,
      showMoreLabel
    }
  } = useWordPressContext()

  const {isCookiesFormOpen, setIsCookiesFormOpen} =
    useContext(CookiesFormContext)

  const cookiesObj = new CookiesObject()

  const [cookiesNameArray, setCookiesNameArray] = useState({})

  useEffect(() => {
    cookies.forEach((cookieElment) => {
      const obj = {}
      obj[cookieElment.cookieName] =
        cookiesObj.get(cookieElment.cookieName) == 'true' ||
        cookieElment.cookieName == cookieNameFunctional
          ? true
          : false
      setCookiesNameArray((prevObject) => ({...prevObject, ...obj}))
    })
  }, [cookies])

  const handleSubmit = (onlyFunctional) => {
    cookiesObj.set(cookieNameFunctional, true, cookieSettings)

    if (!onlyFunctional) {
      cookiesObj.set(cookieNameStatistics, true, cookieSettings)
    }
    // if (property === 'allowStatistics' && cookiesNameArray[property] == true)
    //   gtag('consent', 'update', {
    //     ad_storage: 'granted',
    //     analytics_storage: 'granted'
    //   })
    // else
    //   gtag('consent', 'update', {
    //     ad_storage: 'denied',
    //     analytics_storage: 'denied'
    //   })

    handleCloseForm()
  }

  const handleCloseForm = () => {
    enableScroll(true)
    setIsCookiesFormOpen(false)
  }

  return (
    <div
      className={cn(styles.cookies, isCookiesFormOpen ? styles.isActive : '')}
    >
      <div className={styles.wrapper}>
        <div className={styles.closeIconWrapper}>
          <Icon
            size={40}
            sizeViewbox={40}
            icon="closeForm"
            className={styles.closeForm}
            onClick={() => handleCloseForm()}
          />
        </div>
        <div className={styles.title}>{cookiesTitle}</div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{__html: cookieBannerDescription}}
        />
        {cookies &&
          cookies.map((cookie, i) => (
            <CookieHelper
              key={i}
              cookie={cookie}
              object={cookiesNameArray}
              setObject={setCookiesNameArray}
              showMoreLabel={showMoreLabel}
            />
          ))}
        <div className={styles.buttonsWrapper}>
          <Button
            isSmall
            text={acceptCookiesLabel}
            type="filled"
            icon={null}
            onClick={() => handleSubmit(false)}
            className={styles.button}
          />
          <Button
            isSmall
            text={rejectCookiesLabel}
            type="outline"
            icon={null}
            onClick={() => handleSubmit(true)}
            className={styles.button}
          />
        </div>
      </div>
    </div>
  )
}

export default Cookies
