import cn from 'classnames'
import React from 'react'

import styles from './Container.module.scss'

type ContainerProps = {
  children: React.ReactNode
  size?: string
  removePaddingBottom?: boolean
  changePaddingBottom?: boolean
  constantPaddingBottom?: boolean
  removeBorders?: boolean
  removePaddingMobile?: boolean
  removeLeftBorder?: boolean
}

export default function Container({
  children,
  removeBorders,
  removePaddingMobile,
  removeLeftBorder
}: ContainerProps) {
  return (
    <div className={cn(removeBorders ? '' : styles.borderBottom)}>
      <div
        className={cn(
          styles.container,
          removePaddingMobile ? styles.removePaddingMobile : ''
        )}
      >
        <div
          className={cn(
            removeBorders ? '' : styles.sideBorders,
            removeLeftBorder ? styles.removeLeftBorder : ''
          )}
        >
          {children && children}
        </div>
      </div>
    </div>
  )
}
