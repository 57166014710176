/**
 * Render the Logo component.
 *
 * @author WebDevStudios
 * @param  {object}  props           Logo props.
 * @param  {string}  props.className Optional classname for the element.
 * @param  {Array}   props.type      Type or style of the logo.
 * @return {Element}                 The Logo component.
 */
type Props = {
  className?: string
  activeLocale: string
}

export default function TeaserLogo({className, activeLocale}: Props) {
  // prettier-ignore
  /* eslint-disable */
  return activeLocale === 'en' ? (
<svg 
data-aos="fade-up"
data-aos-delay="1000"
className={className} width="297" height="91" viewBox="0 0 297 91" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M3.887 46.495V55H1.565V36.1H13.715V38.179H3.887V44.389H12.986V46.495H3.887Z" fill="white"/>
<path d="M28.6306 48.196C28.6306 52.543 25.9036 55.297 22.0426 55.297C18.2086 55.297 15.4816 52.543 15.4816 48.196C15.4816 43.849 18.2086 41.095 22.0426 41.095C25.9036 41.095 28.6306 43.849 28.6306 48.196ZM22.0156 43.147C19.4236 43.147 17.7766 45.091 17.7766 48.196C17.7766 51.409 19.5316 53.245 22.0966 53.245C24.6886 53.245 26.3356 51.301 26.3356 48.196C26.3356 44.983 24.5806 43.147 22.0156 43.147Z" fill="white"/>
<path d="M39.1853 41.203V43.336H38.5643C35.8643 43.336 34.3793 44.875 34.3793 47.899V55H32.1383V41.338H34.3523V43.714C35.0273 42.067 36.4853 41.203 38.5373 41.203H39.1853Z" fill="white"/>
<path d="M54.7261 53.002V55H52.7551C51.5671 55 50.6221 54.757 49.9471 54.271C49.2721 53.758 48.9481 52.786 48.9481 51.355V43.336H46.3291V41.338H48.9481V37.693L51.1891 37.072V41.338H54.4021V43.336H51.1891V51.085C51.1891 52.381 51.5671 53.002 52.9981 53.002H54.7261Z" fill="white"/>
<path d="M69.4436 45.739V55H67.2026V45.955C67.2026 44.335 66.1766 43.12 64.2326 43.12C61.7756 43.12 60.2096 44.983 60.2096 48.007V55H57.9686V35.803H60.2096V43.768C61.1276 41.986 62.6126 41.095 64.6376 41.095C67.5266 41.095 69.4436 42.823 69.4436 45.739Z" fill="white"/>
<path d="M85.1534 48.736H75.0014C75.0824 51.679 76.8104 53.299 79.2674 53.299C80.9954 53.299 82.3724 52.462 82.8314 51.031H85.0454C84.6944 52.408 83.9924 53.488 82.8854 54.217C81.7784 54.946 80.5364 55.297 79.1594 55.297C75.7574 55.297 72.8144 52.921 72.8144 48.169C72.8144 43.417 75.7844 41.095 79.1864 41.095C82.5074 41.095 85.1534 43.309 85.1534 47.683V48.736ZM75.0554 46.927H82.9124C82.9124 44.578 81.3734 43.039 79.1324 43.039C76.9724 43.039 75.3524 44.308 75.0554 46.927Z" fill="white"/>
<path d="M108.592 55L106.567 49.897H97.8464L95.8214 55H93.3914L101.086 36.1H103.408L111.076 55H108.592ZM98.6834 47.791H105.757L102.22 38.854L98.6834 47.791Z" fill="white"/>
<path d="M125.712 35.803V55H123.552V52.327C122.823 54.136 121.284 55.297 118.881 55.297C114.831 55.297 112.698 51.922 112.698 48.196C112.698 44.497 114.858 41.095 118.908 41.095C121.095 41.095 122.688 42.067 123.471 43.579V35.803H125.712ZM123.417 48.088C123.417 46.657 123.066 45.469 122.337 44.524C121.608 43.579 120.555 43.093 119.178 43.093C117.801 43.093 116.748 43.579 116.046 44.551C115.344 45.523 114.993 46.738 114.993 48.169C114.993 49.6 115.344 50.815 116.046 51.787C116.748 52.732 117.774 53.218 119.151 53.218C120.528 53.218 121.581 52.732 122.31 51.733C123.039 50.734 123.417 49.519 123.417 48.088Z" fill="white"/>
<path d="M139.056 41.338H141.405L136.059 55H133.764L128.418 41.338H130.848L134.952 52.543L139.056 41.338Z" fill="white"/>
<path d="M154.276 45.442V55H152.089V52.786C150.982 54.595 149.065 55.297 147.256 55.297C144.772 55.297 142.909 53.839 142.909 51.328C142.909 49.924 143.314 48.952 144.151 48.412C144.988 47.872 145.933 47.494 147.04 47.305L149.74 46.846C151.36 46.549 152.062 46.225 152.062 45.037C152.062 43.714 151.009 43.066 149.281 43.066C147.229 43.066 145.96 43.957 145.609 45.469H143.341C143.746 42.688 146.149 41.095 149.389 41.095C152.494 41.095 154.276 42.499 154.276 45.442ZM149.713 48.52L148.066 48.79C146.23 49.087 145.177 49.519 145.177 51.139C145.177 52.651 146.203 53.299 147.715 53.299C149.983 53.299 152.035 51.841 152.035 49.141V47.899C151.387 48.196 150.874 48.331 149.713 48.52Z" fill="white"/>
<path d="M170.065 45.739V55H167.824V45.955C167.824 44.335 166.798 43.12 164.854 43.12C162.397 43.12 160.831 44.983 160.831 48.007V55H158.59V41.338H160.804V43.714C161.722 41.959 163.207 41.095 165.259 41.095C168.148 41.095 170.065 42.823 170.065 45.739Z" fill="white"/>
<path d="M183.48 50.113H185.694C185.478 53.434 182.859 55.297 179.808 55.297C176.244 55.297 173.436 52.921 173.436 48.277C173.436 43.714 176.163 41.095 180.051 41.095C183.291 41.095 185.613 42.931 185.721 46.09H183.534C183.426 44.254 182.076 43.039 179.943 43.039C177.432 43.039 175.704 44.794 175.704 48.142C175.704 51.598 177.567 53.272 179.862 53.272C181.833 53.272 183.399 52.03 183.48 50.113Z" fill="white"/>
<path d="M200.616 48.736H190.464C190.545 51.679 192.273 53.299 194.73 53.299C196.458 53.299 197.835 52.462 198.294 51.031H200.508C200.157 52.408 199.455 53.488 198.348 54.217C197.241 54.946 195.999 55.297 194.622 55.297C191.22 55.297 188.277 52.921 188.277 48.169C188.277 43.417 191.247 41.095 194.649 41.095C197.97 41.095 200.616 43.309 200.616 47.683V48.736ZM190.518 46.927H198.375C198.375 44.578 196.836 43.039 194.595 43.039C192.435 43.039 190.815 44.308 190.518 46.927Z" fill="white"/>
<path d="M223.017 45.388V55H220.776V46.117C220.776 44.146 219.696 43.147 218.049 43.147C215.97 43.147 214.674 44.794 214.674 47.629V55H212.46V46.117C212.46 44.146 211.38 43.147 209.733 43.147C207.681 43.147 206.358 44.767 206.358 47.602V55H204.117V41.338H206.304V43.552C207.033 41.851 208.383 41.095 210.084 41.095C212.163 41.095 213.702 42.337 214.107 43.984C214.674 42.391 215.889 41.095 218.454 41.095C221.154 41.095 223.017 42.607 223.017 45.388Z" fill="white"/>
<path d="M238.737 48.736H228.585C228.666 51.679 230.394 53.299 232.851 53.299C234.579 53.299 235.956 52.462 236.415 51.031H238.629C238.278 52.408 237.576 53.488 236.469 54.217C235.362 54.946 234.12 55.297 232.743 55.297C229.341 55.297 226.398 52.921 226.398 48.169C226.398 43.417 229.368 41.095 232.77 41.095C236.091 41.095 238.737 43.309 238.737 47.683V48.736ZM228.639 46.927H236.496C236.496 44.578 234.957 43.039 232.716 43.039C230.556 43.039 228.936 44.308 228.639 46.927Z" fill="white"/>
<path d="M253.712 45.739V55H251.471V45.955C251.471 44.335 250.445 43.12 248.501 43.12C246.044 43.12 244.478 44.983 244.478 48.007V55H242.237V41.338H244.451V43.714C245.369 41.959 246.854 41.095 248.906 41.095C251.795 41.095 253.712 42.823 253.712 45.739Z" fill="white"/>
<path d="M264.67 53.002V55H262.699C261.511 55 260.566 54.757 259.891 54.271C259.216 53.758 258.892 52.786 258.892 51.355V43.336H256.273V41.338H258.892V37.693L261.133 37.072V41.338H264.346V43.336H261.133V51.085C261.133 52.381 261.511 53.002 262.942 53.002H264.67Z" fill="white"/>
<path d="M286.468 48.196C286.468 52.543 283.741 55.297 279.88 55.297C276.046 55.297 273.319 52.543 273.319 48.196C273.319 43.849 276.046 41.095 279.88 41.095C283.741 41.095 286.468 43.849 286.468 48.196ZM279.853 43.147C277.261 43.147 275.614 45.091 275.614 48.196C275.614 51.409 277.369 53.245 279.934 53.245C282.526 53.245 284.173 51.301 284.173 48.196C284.173 44.983 282.418 43.147 279.853 43.147Z" fill="white"/>
<path d="M296.375 37.801H294.89C293.459 37.801 293.081 38.422 293.081 39.718V41.338H296.024V43.336H293.081V55H290.84V43.336H288.626V41.338H290.84V39.583C290.84 36.667 292.325 35.803 294.62 35.803H296.375V37.801Z" fill="white"/>
<path d="M9.611 90.486C3.914 90.594 0.565998 85.788 0.619998 80.442C0.565998 75.123 3.941 70.56 9.8 70.641C14.12 70.641 17.117 72.936 17.549 77.04H15.092C14.768 74.421 12.635 72.801 9.692 72.801C5.399 72.801 2.996 76.311 2.996 80.415C2.996 84.627 5.426 88.299 9.827 88.299C12.959 88.299 15.146 86.517 15.227 83.358H17.657C17.603 87.678 14.066 90.486 9.611 90.486Z" fill="white"/>
<path d="M31.7265 80.442V90H29.5395V87.786C28.4325 89.595 26.5155 90.297 24.7065 90.297C22.2225 90.297 20.3595 88.839 20.3595 86.328C20.3595 84.924 20.7645 83.952 21.6015 83.412C22.4385 82.872 23.3835 82.494 24.4905 82.305L27.1905 81.846C28.8105 81.549 29.5125 81.225 29.5125 80.037C29.5125 78.714 28.4595 78.066 26.7315 78.066C24.6795 78.066 23.4105 78.957 23.0595 80.469H20.7915C21.1965 77.688 23.5995 76.095 26.8395 76.095C29.9445 76.095 31.7265 77.499 31.7265 80.442ZM27.1635 83.52L25.5165 83.79C23.6805 84.087 22.6275 84.519 22.6275 86.139C22.6275 87.651 23.6535 88.299 25.1655 88.299C27.4335 88.299 29.4855 86.841 29.4855 84.141V82.899C28.8375 83.196 28.3245 83.331 27.1635 83.52Z" fill="white"/>
<path d="M43.0876 76.203V78.336H42.4666C39.7666 78.336 38.2816 79.875 38.2816 82.899V90H36.0406V76.338H38.2546V78.714C38.9296 77.067 40.3876 76.203 42.4396 76.203H43.0876Z" fill="white"/>
<path d="M57.7305 70.803V90H55.5705V87.327C54.8415 89.136 53.3025 90.297 50.8995 90.297C46.8495 90.297 44.7165 86.922 44.7165 83.196C44.7165 79.497 46.8765 76.095 50.9265 76.095C53.1135 76.095 54.7065 77.067 55.4895 78.579V70.803H57.7305ZM55.4355 83.088C55.4355 81.657 55.0845 80.469 54.3555 79.524C53.6265 78.579 52.5735 78.093 51.1965 78.093C49.8195 78.093 48.7665 78.579 48.0645 79.551C47.3625 80.523 47.0115 81.738 47.0115 83.169C47.0115 84.6 47.3625 85.815 48.0645 86.787C48.7665 87.732 49.7925 88.218 51.1695 88.218C52.5465 88.218 53.5995 87.732 54.3285 86.733C55.0575 85.734 55.4355 84.519 55.4355 83.088Z" fill="white"/>
<path d="M62.0015 73.26V70.56H64.5125V73.26H62.0015ZM62.1365 90V76.338H64.3775V90H62.1365Z" fill="white"/>
<path d="M80.9832 83.196C80.9832 87.543 78.2562 90.297 74.3952 90.297C70.5612 90.297 67.8342 87.543 67.8342 83.196C67.8342 78.849 70.5612 76.095 74.3952 76.095C78.2562 76.095 80.9832 78.849 80.9832 83.196ZM74.3682 78.147C71.7762 78.147 70.1292 80.091 70.1292 83.196C70.1292 86.409 71.8842 88.245 74.4492 88.245C77.0412 88.245 78.6882 86.301 78.6882 83.196C78.6882 79.983 76.9332 78.147 74.3682 78.147Z" fill="white"/>
<path d="M93.1102 76.338H95.4592L90.1132 90H87.8182L82.4722 76.338H84.9022L89.0062 87.543L93.1102 76.338Z" fill="white"/>
<path d="M108.331 80.442V90H106.144V87.786C105.037 89.595 103.12 90.297 101.311 90.297C98.8266 90.297 96.9636 88.839 96.9636 86.328C96.9636 84.924 97.3686 83.952 98.2056 83.412C99.0426 82.872 99.9876 82.494 101.095 82.305L103.795 81.846C105.415 81.549 106.117 81.225 106.117 80.037C106.117 78.714 105.064 78.066 103.336 78.066C101.284 78.066 100.015 78.957 99.6636 80.469H97.3956C97.8006 77.688 100.204 76.095 103.444 76.095C106.549 76.095 108.331 77.499 108.331 80.442ZM103.768 83.52L102.121 83.79C100.285 84.087 99.2316 84.519 99.2316 86.139C99.2316 87.651 100.258 88.299 101.77 88.299C104.038 88.299 106.09 86.841 106.09 84.141V82.899C105.442 83.196 104.929 83.331 103.768 83.52Z" fill="white"/>
<path d="M123.013 86.004C123.013 87.381 122.473 88.461 121.42 89.19C120.367 89.919 119.044 90.297 117.505 90.297C115.912 90.297 114.562 89.919 113.428 89.136C112.321 88.353 111.7 87.219 111.565 85.734H113.887C114.13 87.462 115.642 88.299 117.667 88.299C119.422 88.299 120.637 87.597 120.637 86.301C120.637 85.086 119.719 84.654 118.018 84.411C116.668 84.195 115.615 84.006 114.886 83.817C114.157 83.628 113.455 83.25 112.834 82.683C112.213 82.116 111.916 81.279 111.916 80.199C111.916 77.58 114.103 76.095 117.127 76.095C118.666 76.095 119.962 76.473 121.015 77.202C122.068 77.931 122.608 78.984 122.635 80.415H120.34C120.313 78.903 118.936 78.093 117.127 78.093C115.48 78.093 114.238 78.768 114.238 80.091C114.238 81.495 115.399 81.9 117.802 82.251C118.936 82.413 119.827 82.575 120.475 82.764C121.771 83.115 123.013 84.087 123.013 86.004Z" fill="white"/>
<path d="M135.478 85.113H137.692C137.476 88.434 134.857 90.297 131.806 90.297C128.242 90.297 125.434 87.921 125.434 83.277C125.434 78.714 128.161 76.095 132.049 76.095C135.289 76.095 137.611 77.931 137.719 81.09H135.532C135.424 79.254 134.074 78.039 131.941 78.039C129.43 78.039 127.702 79.794 127.702 83.142C127.702 86.598 129.565 88.272 131.86 88.272C133.831 88.272 135.397 87.03 135.478 85.113Z" fill="white"/>
<path d="M152.317 76.338V90H150.13V87.597C149.239 89.298 147.808 90.162 145.837 90.162C144.46 90.162 143.326 89.73 142.435 88.866C141.544 88.002 141.085 86.787 141.085 85.248V76.338H143.326V84.87C143.326 87.003 144.568 88.164 146.323 88.164C148.294 88.164 150.076 86.463 150.076 83.25V76.338H152.317Z" fill="white"/>
<path d="M159.015 70.803V90H156.774V70.803H159.015Z" fill="white"/>
<path d="M173.89 80.442V90H171.703V87.786C170.596 89.595 168.679 90.297 166.87 90.297C164.386 90.297 162.523 88.839 162.523 86.328C162.523 84.924 162.928 83.952 163.765 83.412C164.602 82.872 165.547 82.494 166.654 82.305L169.354 81.846C170.974 81.549 171.676 81.225 171.676 80.037C171.676 78.714 170.623 78.066 168.895 78.066C166.843 78.066 165.574 78.957 165.223 80.469H162.955C163.36 77.688 165.763 76.095 169.003 76.095C172.108 76.095 173.89 77.499 173.89 80.442ZM169.327 83.52L167.68 83.79C165.844 84.087 164.791 84.519 164.791 86.139C164.791 87.651 165.817 88.299 167.329 88.299C169.597 88.299 171.649 86.841 171.649 84.141V82.899C171.001 83.196 170.488 83.331 169.327 83.52Z" fill="white"/>
<path d="M185.251 76.203V78.336H184.63C181.93 78.336 180.445 79.875 180.445 82.899V90H178.204V76.338H180.418V78.714C181.093 77.067 182.551 76.203 184.603 76.203H185.251Z" fill="white"/>
<path d="M209.291 71.1V90H206.969V81.522H197.006V90H194.684V71.1H197.006V79.443H206.969V71.1H209.291Z" fill="white"/>
<path d="M225.422 83.736H215.27C215.351 86.679 217.079 88.299 219.536 88.299C221.264 88.299 222.641 87.462 223.1 86.031H225.314C224.963 87.408 224.261 88.488 223.154 89.217C222.047 89.946 220.805 90.297 219.428 90.297C216.026 90.297 213.083 87.921 213.083 83.169C213.083 78.417 216.053 76.095 219.455 76.095C222.776 76.095 225.422 78.309 225.422 82.683V83.736ZM215.324 81.927H223.181C223.181 79.578 221.642 78.039 219.401 78.039C217.241 78.039 215.621 79.308 215.324 81.927Z" fill="white"/>
<path d="M239.344 80.442V90H237.157V87.786C236.05 89.595 234.133 90.297 232.324 90.297C229.84 90.297 227.977 88.839 227.977 86.328C227.977 84.924 228.382 83.952 229.219 83.412C230.056 82.872 231.001 82.494 232.108 82.305L234.808 81.846C236.428 81.549 237.13 81.225 237.13 80.037C237.13 78.714 236.077 78.066 234.349 78.066C232.297 78.066 231.028 78.957 230.677 80.469H228.409C228.814 77.688 231.217 76.095 234.457 76.095C237.562 76.095 239.344 77.499 239.344 80.442ZM234.781 83.52L233.134 83.79C231.298 84.087 230.245 84.519 230.245 86.139C230.245 87.651 231.271 88.299 232.783 88.299C235.051 88.299 237.103 86.841 237.103 84.141V82.899C236.455 83.196 235.942 83.331 234.781 83.52Z" fill="white"/>
<path d="M245.899 70.803V90H243.658V70.803H245.899Z" fill="white"/>
<path d="M256.994 88.002V90H255.023C253.835 90 252.89 89.757 252.215 89.271C251.54 88.758 251.216 87.786 251.216 86.355V78.336H248.597V76.338H251.216V72.693L253.457 72.072V76.338H256.67V78.336H253.457V86.085C253.457 87.381 253.835 88.002 255.266 88.002H256.994Z" fill="white"/>
<path d="M271.712 80.739V90H269.471V80.955C269.471 79.335 268.445 78.12 266.501 78.12C264.044 78.12 262.478 79.983 262.478 83.007V90H260.237V70.803H262.478V78.768C263.396 76.986 264.881 76.095 266.906 76.095C269.795 76.095 271.712 77.823 271.712 80.739Z" fill="white"/>
</g>
<path d="M16.82 10.28C16.874 15.275 13.499 20.081 7.42399 20H1.56499V1.1H7.586C13.634 1.1 16.82 5.285 16.82 10.28ZM14.39 10.415C14.39 6.473 12.014 3.179 7.45099 3.179H3.88699V17.921H7.397C11.987 17.921 14.39 14.357 14.39 10.415Z" fill="white"/>
<path d="M35.4696 1.1V20H33.1476V11.522H23.1846V20H20.8626V1.1H23.1846V9.443H33.1476V1.1H35.4696Z" fill="white"/>
<path d="M54.0306 17.921V20H39.5316V17.921L50.8716 3.179H39.8286V1.1H53.6526V3.179L42.3936 17.921H54.0306Z" fill="white"/>
<path d="M71.9171 14.546C71.9171 17.327 69.6491 20 65.5991 20H57.8231V1.1H65.9771C69.3521 1.1 71.0801 3.071 71.0801 5.366C71.0801 7.175 70.0541 8.984 68.2451 9.767C70.6481 10.55 71.9171 12.413 71.9171 14.546ZM60.1181 9.011H65.1941C67.4621 9.011 68.7581 7.553 68.7581 5.906C68.7581 4.367 67.6781 3.179 65.6261 3.179H60.1181V9.011ZM69.5411 14.492C69.5411 12.575 68.2181 11.036 65.8691 11.036H60.1181V17.921H65.6801C68.2451 17.921 69.5411 16.409 69.5411 14.492Z" fill="white"/>
<path d="M84.4757 11.495V20H82.1537V1.1H94.3037V3.179H84.4757V9.389H93.5747V11.495H84.4757Z" fill="white"/>
<path d="M109.219 13.196C109.219 17.543 106.492 20.297 102.631 20.297C98.7973 20.297 96.0703 17.543 96.0703 13.196C96.0703 8.849 98.7973 6.095 102.631 6.095C106.492 6.095 109.219 8.849 109.219 13.196ZM102.604 8.147C100.012 8.147 98.3653 10.091 98.3653 13.196C98.3653 16.409 100.12 18.245 102.685 18.245C105.277 18.245 106.924 16.301 106.924 13.196C106.924 9.983 105.169 8.147 102.604 8.147Z" fill="white"/>
<path d="M123.824 6.338V20H121.637V17.597C120.746 19.298 119.315 20.162 117.344 20.162C115.967 20.162 114.833 19.73 113.942 18.866C113.051 18.002 112.592 16.787 112.592 15.248V6.338H114.833V14.87C114.833 17.003 116.075 18.164 117.83 18.164C119.801 18.164 121.583 16.463 121.583 13.25V6.338H123.824Z" fill="white"/>
<path d="M139.755 10.739V20H137.514V10.955C137.514 9.335 136.488 8.12 134.544 8.12C132.087 8.12 130.521 9.983 130.521 13.007V20H128.28V6.338H130.494V8.714C131.412 6.959 132.897 6.095 134.949 6.095C137.838 6.095 139.755 7.823 139.755 10.739Z" fill="white"/>
<path d="M156.14 0.802998V20H153.98V17.327C153.251 19.136 151.712 20.297 149.309 20.297C145.259 20.297 143.126 16.922 143.126 13.196C143.126 9.497 145.286 6.095 149.336 6.095C151.523 6.095 153.116 7.067 153.899 8.579V0.802998H156.14ZM153.845 13.088C153.845 11.657 153.494 10.469 152.765 9.524C152.036 8.579 150.983 8.093 149.606 8.093C148.229 8.093 147.176 8.579 146.474 9.551C145.772 10.523 145.421 11.738 145.421 13.169C145.421 14.6 145.772 15.815 146.474 16.787C147.176 17.732 148.202 18.218 149.579 18.218C150.956 18.218 152.009 17.732 152.738 16.733C153.467 15.734 153.845 14.519 153.845 13.088Z" fill="white"/>
<path d="M171.022 10.442V20H168.835V17.786C167.728 19.595 165.811 20.297 164.002 20.297C161.518 20.297 159.655 18.839 159.655 16.328C159.655 14.924 160.06 13.952 160.897 13.412C161.734 12.872 162.679 12.494 163.786 12.305L166.486 11.846C168.106 11.549 168.808 11.225 168.808 10.037C168.808 8.714 167.755 8.066 166.027 8.066C163.975 8.066 162.706 8.957 162.355 10.469H160.087C160.492 7.688 162.895 6.095 166.135 6.095C169.24 6.095 171.022 7.499 171.022 10.442ZM166.459 13.52L164.812 13.79C162.976 14.087 161.923 14.519 161.923 16.139C161.923 17.651 162.949 18.299 164.461 18.299C166.729 18.299 168.781 16.841 168.781 14.141V12.899C168.133 13.196 167.62 13.331 166.459 13.52Z" fill="white"/>
<path d="M181.978 18.002V20H180.007C178.819 20 177.874 19.757 177.199 19.271C176.524 18.758 176.2 17.786 176.2 16.355V8.336H173.581V6.338H176.2V2.693L178.441 2.072V6.338H181.654V8.336H178.441V16.085C178.441 17.381 178.819 18.002 180.25 18.002H181.978Z" fill="white"/>
<path d="M185.032 3.26V0.559998H187.543V3.26H185.032ZM185.167 20V6.338H187.408V20H185.167Z" fill="white"/>
<path d="M204.014 13.196C204.014 17.543 201.286 20.297 197.425 20.297C193.591 20.297 190.865 17.543 190.865 13.196C190.865 8.849 193.591 6.095 197.425 6.095C201.286 6.095 204.014 8.849 204.014 13.196ZM197.399 8.147C194.807 8.147 193.16 10.091 193.16 13.196C193.16 16.409 194.915 18.245 197.48 18.245C200.072 18.245 201.718 16.301 201.718 13.196C201.718 9.983 199.964 8.147 197.399 8.147Z" fill="white"/>
<path d="M218.996 10.739V20H216.755V10.955C216.755 9.335 215.729 8.12 213.785 8.12C211.328 8.12 209.762 9.983 209.762 13.007V20H207.521V6.338H209.735V8.714C210.653 6.959 212.138 6.095 214.19 6.095C217.079 6.095 218.996 7.823 218.996 10.739Z" fill="white"/>
</svg>


  ): (
    <svg 
    data-aos="fade-up"
data-aos-delay="1000"
className={className}
    width="255" height="91" viewBox="0 0 255 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
    <path d="M152.618 80.7386V89.9996H150.377V80.9546C150.377 79.3346 149.351 78.1196 147.407 78.1196C144.95 78.1196 143.384 79.9826 143.384 83.0066V89.9996H141.143V76.3376H143.357V78.7136C144.275 76.9586 145.76 76.0946 147.812 76.0946C150.701 76.0946 152.618 77.8226 152.618 80.7386Z" fill="white"/>
    <path d="M134.365 73.2596V70.5596H136.876V73.2596H134.365ZM134.5 89.9996V76.3376H136.741V89.9996H134.5Z" fill="white"/>
    <path d="M130.92 88.0016V89.9996H119.931V88.0016L128.166 78.3356H119.985V76.3376H130.92V78.3356L122.658 88.0016H130.92Z" fill="white"/>
    <path d="M113.963 73.2596V70.5596H116.474V73.2596H113.963ZM114.098 89.9996V76.3376H116.339V89.9996H114.098Z" fill="white"/>
    <path d="M109.692 70.8026V89.9996H107.532V87.3266C106.803 89.1356 105.264 90.2966 102.861 90.2966C98.8111 90.2966 96.6781 86.9216 96.6781 83.1956C96.6781 79.4966 98.8381 76.0946 102.888 76.0946C105.075 76.0946 106.668 77.0666 107.451 78.5786V70.8026H109.692ZM107.397 83.0876C107.397 81.6566 107.046 80.4686 106.317 79.5236C105.588 78.5786 104.535 78.0926 103.158 78.0926C101.781 78.0926 100.728 78.5786 100.026 79.5506C99.3241 80.5226 98.9731 81.7376 98.9731 83.1686C98.9731 84.5996 99.3241 85.8146 100.026 86.7866C100.728 87.7316 101.754 88.2176 103.131 88.2176C104.508 88.2176 105.561 87.7316 106.29 86.7326C107.019 85.7336 107.397 84.5186 107.397 83.0876Z" fill="white"/>
    <path d="M94.1228 83.7356H83.9708C84.0518 86.6786 85.7798 88.2986 88.2368 88.2986C89.9648 88.2986 91.3418 87.4616 91.8008 86.0306H94.0148C93.6638 87.4076 92.9618 88.4876 91.8548 89.2166C90.7478 89.9456 89.5058 90.2966 88.1288 90.2966C84.7268 90.2966 81.7838 87.9206 81.7838 83.1686C81.7838 78.4166 84.7538 76.0946 88.1558 76.0946C91.4768 76.0946 94.1228 78.3086 94.1228 82.6826V83.7356ZM84.0248 81.9266H91.8818C91.8818 79.5776 90.3428 78.0386 88.1018 78.0386C85.9418 78.0386 84.3218 79.3076 84.0248 81.9266Z" fill="white"/>
    <path d="M78.4025 80.3876V89.9996H76.1615V81.1166C76.1615 79.1456 75.0815 78.1466 73.4345 78.1466C71.3555 78.1466 70.0595 79.7936 70.0595 82.6286V89.9996H67.8455V81.1166C67.8455 79.1456 66.7655 78.1466 65.1185 78.1466C63.0665 78.1466 61.7435 79.7666 61.7435 82.6016V89.9996H59.5025V76.3376H61.6895V78.5516C62.4185 76.8506 63.7685 76.0946 65.4695 76.0946C67.5485 76.0946 69.0875 77.3366 69.4925 78.9836C70.0595 77.3906 71.2745 76.0946 73.8395 76.0946C76.5395 76.0946 78.4025 77.6066 78.4025 80.3876Z" fill="white"/>
    <path d="M55.8683 88.0016V89.9996H44.8793V88.0016L53.1143 78.3356H44.9333V76.3376H55.8683V78.3356L47.6063 88.0016H55.8683Z" fill="white"/>
    <path d="M42.8517 76.2026V78.3356H42.2307C39.5307 78.3356 38.0457 79.8746 38.0457 82.8986V89.9996H35.8047V76.3376H38.0187V78.7136C38.6937 77.0666 40.1517 76.2026 42.2037 76.2026H42.8517Z" fill="white"/>
    <path d="M32.3044 83.7356H22.1524C22.2334 86.6786 23.9614 88.2986 26.4184 88.2986C28.1464 88.2986 29.5234 87.4616 29.9824 86.0306H32.1964C31.8454 87.4076 31.1434 88.4876 30.0364 89.2166C28.9294 89.9456 27.6874 90.2966 26.3104 90.2966C22.9084 90.2966 19.9654 87.9206 19.9654 83.1686C19.9654 78.4166 22.9354 76.0946 26.3374 76.0946C29.6584 76.0946 32.3044 78.3086 32.3044 82.6826V83.7356ZM22.2064 81.9266H30.0634C30.0634 79.5776 28.5244 78.0386 26.2834 78.0386C24.1234 78.0386 22.5034 79.3076 22.2064 81.9266Z" fill="white"/>
    <path d="M16.1734 71.0996V89.9996H13.8514V81.5216H3.88841V89.9996H1.56641V71.0996H3.88841V79.4426H13.8514V71.0996H16.1734Z" fill="white"/>
    <path d="M254.283 41.2026V43.3356H253.662C250.962 43.3356 249.477 44.8746 249.477 47.8986V54.9996H247.236V41.3376H249.45V43.7136C250.125 42.0666 251.583 41.2026 253.635 41.2026H254.283Z" fill="white"/>
    <path d="M243.736 48.7356H233.584C233.665 51.6786 235.393 53.2986 237.85 53.2986C239.578 53.2986 240.955 52.4616 241.414 51.0306H243.628C243.277 52.4076 242.575 53.4876 241.468 54.2166C240.361 54.9456 239.119 55.2966 237.742 55.2966C234.34 55.2966 231.397 52.9206 231.397 48.1686C231.397 43.4166 234.367 41.0946 237.769 41.0946C241.09 41.0946 243.736 43.3086 243.736 47.6826V48.7356ZM233.638 46.9266H241.495C241.495 44.5776 239.956 43.0386 237.715 43.0386C235.555 43.0386 233.935 44.3076 233.638 46.9266Z" fill="white"/>
    <path d="M227.881 35.8026V54.9996H225.721V52.3266C224.992 54.1356 223.453 55.2966 221.05 55.2966C217 55.2966 214.867 51.9216 214.867 48.1956C214.867 44.4966 217.027 41.0946 221.077 41.0946C223.264 41.0946 224.857 42.0666 225.64 43.5786V35.8026H227.881ZM225.586 48.0876C225.586 46.6566 225.235 45.4686 224.506 44.5236C223.777 43.5786 222.724 43.0926 221.347 43.0926C219.97 43.0926 218.917 43.5786 218.215 44.5506C217.513 45.5226 217.162 46.7376 217.162 48.1686C217.162 49.5996 217.513 50.8146 218.215 51.7866C218.917 52.7316 219.943 53.2176 221.32 53.2176C222.697 53.2176 223.75 52.7316 224.479 51.7326C225.208 50.7336 225.586 49.5186 225.586 48.0876Z" fill="white"/>
    <path d="M204.882 45.7386V54.9996H202.641V45.9546C202.641 44.3346 201.615 43.1196 199.671 43.1196C197.214 43.1196 195.648 44.9826 195.648 48.0066V54.9996H193.407V41.3376H195.621V43.7136C196.539 41.9586 198.024 41.0946 200.076 41.0946C202.965 41.0946 204.882 42.8226 204.882 45.7386Z" fill="white"/>
    <path d="M186.629 38.2596V35.5596H189.14V38.2596H186.629ZM186.764 54.9996V41.3376H189.005V54.9996H186.764Z" fill="white"/>
    <path d="M176.697 53.0016V54.9996H174.726C173.538 54.9996 172.593 54.7566 171.918 54.2706C171.243 53.7576 170.919 52.7856 170.919 51.3546V43.3356H168.3V41.3376H170.919V37.6926L173.16 37.0716V41.3376H176.373V43.3356H173.16V51.0846C173.16 52.3806 173.538 53.0016 174.969 53.0016H176.697Z" fill="white"/>
    <path d="M166.549 53.0016V54.9996H164.578C163.39 54.9996 162.445 54.7566 161.77 54.2706C161.095 53.7576 160.771 52.7856 160.771 51.3546V43.3356H158.152V41.3376H160.771V37.6926L163.012 37.0716V41.3376H166.225V43.3356H163.012V51.0846C163.012 52.3806 163.39 53.0016 164.821 53.0016H166.549Z" fill="white"/>
    <path d="M153.129 38.2596V35.5596H155.64V38.2596H153.129ZM153.264 54.9996V41.3376H155.505V54.9996H153.264Z" fill="white"/>
    <path d="M150.48 41.2026V43.3356H149.859C147.159 43.3356 145.674 44.8746 145.674 47.8986V54.9996H143.433V41.3376H145.647V43.7136C146.322 42.0666 147.78 41.2026 149.832 41.2026H150.48Z" fill="white"/>
    <path d="M139.118 45.7386V54.9996H136.877V45.9546C136.877 44.3346 135.851 43.1196 133.907 43.1196C131.45 43.1196 129.884 44.9826 129.884 48.0066V54.9996H127.643V35.8026H129.884V43.7676C130.802 41.9856 132.287 41.0946 134.312 41.0946C137.201 41.0946 139.118 42.8226 139.118 45.7386Z" fill="white"/>
    <path d="M121.9 50.1126H124.114C123.898 53.4336 121.279 55.2966 118.228 55.2966C114.664 55.2966 111.856 52.9206 111.856 48.2766C111.856 43.7136 114.583 41.0946 118.471 41.0946C121.711 41.0946 124.033 42.9306 124.141 46.0896H121.954C121.846 44.2536 120.496 43.0386 118.363 43.0386C115.852 43.0386 114.124 44.7936 114.124 48.1416C114.124 51.5976 115.987 53.2716 118.282 53.2716C120.253 53.2716 121.819 52.0296 121.9 50.1126Z" fill="white"/>
    <path d="M109.435 51.0036C109.435 52.3806 108.895 53.4606 107.842 54.1896C106.789 54.9186 105.466 55.2966 103.927 55.2966C102.334 55.2966 100.984 54.9186 99.85 54.1356C98.743 53.3526 98.122 52.2186 97.987 50.7336H100.309C100.552 52.4616 102.064 53.2986 104.089 53.2986C105.844 53.2986 107.059 52.5966 107.059 51.3006C107.059 50.0856 106.141 49.6536 104.44 49.4106C103.09 49.1946 102.037 49.0056 101.308 48.8166C100.579 48.6276 99.877 48.2496 99.256 47.6826C98.635 47.1156 98.338 46.2786 98.338 45.1986C98.338 42.5796 100.525 41.0946 103.549 41.0946C105.088 41.0946 106.384 41.4726 107.437 42.2016C108.49 42.9306 109.03 43.9836 109.057 45.4146H106.762C106.735 43.9026 105.358 43.0926 103.549 43.0926C101.902 43.0926 100.66 43.7676 100.66 45.0906C100.66 46.4946 101.821 46.8996 104.224 47.2506C105.358 47.4126 106.249 47.5746 106.897 47.7636C108.193 48.1146 109.435 49.0866 109.435 51.0036Z" fill="white"/>
    <path d="M95.8245 53.0016V54.9996H93.8535C92.6655 54.9996 91.7205 54.7566 91.0455 54.2706C90.3705 53.7576 90.0465 52.7856 90.0465 51.3546V43.3356H87.4275V41.3376H90.0465V37.6926L92.2875 37.0716V41.3376H95.5005V43.3356H92.2875V51.0846C92.2875 52.3806 92.6655 53.0016 94.0965 53.0016H95.8245Z" fill="white"/>
    <path d="M86.0813 41.2026V43.3356H85.4603C82.7603 43.3356 81.2753 44.8746 81.2753 47.8986V54.9996H79.0343V41.3376H81.2483V43.7136C81.9233 42.0666 83.3813 41.2026 85.4333 41.2026H86.0813Z" fill="white"/>
    <path d="M75.5266 48.1956C75.5266 52.5426 72.7996 55.2966 68.9386 55.2966C65.1046 55.2966 62.3776 52.5426 62.3776 48.1956C62.3776 43.8486 65.1046 41.0946 68.9386 41.0946C72.7996 41.0946 75.5266 43.8486 75.5266 48.1956ZM68.9116 43.1466C66.3196 43.1466 64.6726 45.0906 64.6726 48.1956C64.6726 51.4086 66.4276 53.2446 68.9926 53.2446C71.5846 53.2446 73.2316 51.3006 73.2316 48.1956C73.2316 44.9826 71.4766 43.1466 68.9116 43.1466Z" fill="white"/>
    <path d="M50.783 46.4946V54.9996H48.461V36.0996H60.611V38.1786H50.783V44.3886H59.882V46.4946H50.783Z" fill="white"/>
    <path d="M39.0285 41.2026V43.3356H38.4075C35.7075 43.3356 34.2225 44.8746 34.2225 47.8986V54.9996H31.9815V41.3376H34.1955V43.7136C34.8705 42.0666 36.3285 41.2026 38.3805 41.2026H39.0285Z" fill="white"/>
    <path d="M27.525 54.9996H25.338V52.5966C24.447 54.2976 23.016 55.1616 21.045 55.1616C19.668 55.1616 18.534 54.7296 17.643 53.8656C16.752 53.0016 16.293 51.7866 16.293 50.2476V41.3376H18.534V49.8696C18.534 52.0026 19.776 53.1636 21.531 53.1636C23.502 53.1636 25.284 51.4626 25.284 48.2496V41.3376H27.525V54.9996ZM20.775 35.9916H18.426V38.6376H20.775V35.9916ZM25.77 35.9916H23.448V38.6376H25.77V35.9916Z" fill="white"/>
    <path d="M3.88841 46.4946V54.9996H1.56641V36.0996H13.7164V38.1786H3.88841V44.3886H12.9874V46.4946H3.88841Z" fill="white"/>
    </g>
    <path d="M177.617 6.33757V19.1626C177.617 21.2416 177.023 22.8076 175.862 23.8606C174.701 24.9406 173.189 25.4806 171.326 25.4806C169.733 25.4806 168.356 25.0756 167.168 24.2926C165.98 23.5096 165.278 22.3216 165.035 20.6746H167.276C167.492 22.3756 168.869 23.5096 171.353 23.5096C174.08 23.5096 175.457 22.1056 175.457 19.2706V16.9756C174.593 18.7036 172.865 19.4326 170.921 19.4326C167.465 19.4326 164.792 17.1106 164.792 12.8176C164.792 8.52457 167.465 6.09457 170.84 6.09457C172.784 6.09457 174.512 6.85057 175.457 8.47057V6.33757H177.617ZM175.295 12.7636C175.295 9.63157 173.567 8.03857 171.218 8.03857C168.761 8.03857 166.979 9.76657 166.979 12.7636C166.979 15.7606 168.761 17.4616 171.218 17.4616C173.567 17.4616 175.295 15.8686 175.295 12.7636Z" fill="white"/>
    <path d="M161.421 10.7386V19.9996H159.18V10.9546C159.18 9.33457 158.154 8.11957 156.21 8.11957C153.753 8.11957 152.187 9.98257 152.187 13.0066V19.9996H149.946V6.33757H152.16V8.71357C153.078 6.95857 154.563 6.09457 156.615 6.09457C159.504 6.09457 161.421 7.82257 161.421 10.7386Z" fill="white"/>
    <path d="M145.49 6.33757V19.9996H143.303V17.5966C142.412 19.2976 140.981 20.1616 139.01 20.1616C137.633 20.1616 136.499 19.7296 135.608 18.8656C134.717 18.0016 134.258 16.7866 134.258 15.2476V6.33757H136.499V14.8696C136.499 17.0026 137.741 18.1636 139.496 18.1636C141.467 18.1636 143.249 16.4626 143.249 13.2496V6.33757H145.49Z" fill="white"/>
    <path d="M131.15 18.0016V19.9996H129.179C127.991 19.9996 127.046 19.7566 126.371 19.2706C125.696 18.7576 125.372 17.7856 125.372 16.3546V8.33557H122.753V6.33757H125.372V2.69257L127.613 2.07157V6.33757H130.826V8.33557H127.613V16.0846C127.613 17.3806 127.991 18.0016 129.422 18.0016H131.15Z" fill="white"/>
    <path d="M121.682 2.80057H120.197C118.766 2.80057 118.388 3.42157 118.388 4.71757V6.33757H121.331V8.33557H118.388V19.9996H116.147V8.33557H113.933V6.33757H116.147V4.58257C116.147 1.66657 117.632 0.80257 119.927 0.80257H121.682V2.80057Z" fill="white"/>
    <path d="M108.505 3.25957V0.55957H111.016V3.25957H108.505ZM108.64 19.9996V6.33757H110.881V19.9996H108.64Z" fill="white"/>
    <path d="M105.452 18.0016V19.9996H103.481C102.293 19.9996 101.348 19.7566 100.673 19.2706C99.9976 18.7576 99.6736 17.7856 99.6736 16.3546V8.33557H97.0546V6.33757H99.6736V2.69257L101.915 2.07157V6.33757H105.128V8.33557H101.915V16.0846C101.915 17.3806 102.293 18.0016 103.724 18.0016H105.452Z" fill="white"/>
    <path d="M88.5001 20.4856C84.7471 20.4856 81.1831 18.2716 81.0751 13.8166H83.5591C83.5861 15.3016 84.0721 16.4086 85.0441 17.1916C86.0431 17.9746 87.2311 18.3526 88.6081 18.3526C91.0111 18.3526 92.7391 17.1376 92.7391 15.1936C92.7391 14.3836 92.4691 13.7356 91.9561 13.2766C91.4431 12.8176 90.9031 12.4666 90.2821 12.2776C89.6881 12.0886 88.8241 11.8456 87.6901 11.5756C86.4751 11.2786 85.4491 10.9546 84.6121 10.6036C82.9651 9.87457 81.6421 8.52457 81.6421 6.14857C81.6421 4.39357 82.2361 3.01657 83.4511 2.07157C84.6661 1.12657 86.2321 0.640571 88.1491 0.640571C90.0661 0.640571 91.6321 1.12657 92.8741 2.07157C94.1431 3.01657 94.7911 4.36657 94.8721 6.09457H92.4961C92.4151 3.90757 90.7951 2.74657 88.2301 2.74657C85.6651 2.74657 83.9371 3.88057 83.9371 6.04057C83.9371 6.79657 84.1531 7.39057 84.5581 7.84957C85.3951 8.74057 86.4751 9.09157 88.5271 9.55057C89.9581 9.90157 91.1191 10.2256 91.9561 10.5226C92.7931 10.8196 93.5491 11.3326 94.1971 12.0346C94.8451 12.7366 95.1691 13.7086 95.1691 14.9236C95.1691 18.4606 92.0911 20.4856 88.5001 20.4856Z" fill="white"/>
    <path d="M71.9185 14.5456C71.9185 17.3266 69.6505 19.9996 65.6005 19.9996H57.8245V1.09957H65.9785C69.3535 1.09957 71.0815 3.07057 71.0815 5.36557C71.0815 7.17457 70.0555 8.98357 68.2465 9.76657C70.6495 10.5496 71.9185 12.4126 71.9185 14.5456ZM60.1195 9.01057H65.1955C67.4635 9.01057 68.7595 7.55257 68.7595 5.90557C68.7595 4.36657 67.6795 3.17857 65.6275 3.17857H60.1195V9.01057ZM69.5425 14.4916C69.5425 12.5746 68.2195 11.0356 65.8705 11.0356H60.1195V17.9206H65.6815C68.2465 17.9206 69.5425 16.4086 69.5425 14.4916Z" fill="white"/>
    <path d="M54.032 17.9206V19.9996H39.533V17.9206L50.873 3.17857H39.83V1.09957H53.654V3.17857L42.395 17.9206H54.032Z" fill="white"/>
    <path d="M35.471 1.09957V19.9996H33.149V11.5216H23.186V19.9996H20.864V1.09957H23.186V9.44257H33.149V1.09957H35.471Z" fill="white"/>
    <path d="M16.8214 10.2796C16.8754 15.2746 13.5004 20.0806 7.42541 19.9996H1.56641V1.09957H7.58741C13.6354 1.09957 16.8214 5.28457 16.8214 10.2796ZM14.3914 10.4146C14.3914 6.47257 12.0154 3.17857 7.45241 3.17857H3.88841V17.9206H7.39841C11.9884 17.9206 14.3914 14.3566 14.3914 10.4146Z" fill="white"/>
    </svg>
       
  )
}
