import Link from 'next/link'

import PropTypes from 'prop-types'

import styles from './Footer.module.scss'

import Button from '@/components/atoms/Button'
import Container from '@/components/atoms/Container'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import {useContext, useEffect} from 'react'
import {disableScroll} from '../../../functions/enableDisableScroll'
// import getGfFormById from '../../../functions/wordpress/gravityForms/getGfFormById'
import {useRouter} from 'next/router'
import {CookiesFormContext} from '../../blocks/custom/Cookies/CookiesFormContext'
// import GravityForm from '../../molecules/GravityForm/GravityForm'

/**
 * Render the Footer component.
 *
 * @author                           WebDevStudios
 * @param  {object}  props              The component attributes as props.
 * @param  {object}  props.social       Yoast SEO social media data.
 * @param  {object}  props.menu         Arrary of menu items.
 * @param            props.menuLegal
 * @param            props.themeOptions
 * @param  {string}  props.siteTitle    Yoast SEO site title.
 * @return {Element}                    The Footer component.
 */
export default function Footer({menu, menuLegal, menuSocial}) {
  const {themeOptions} = useWordPressContext()
  const router = useRouter()

  // const [formData, setFormData] = useState()
  const {setIsCookiesFormOpen} = useContext(CookiesFormContext)

  const handleCookiesForm = () => {
    setIsCookiesFormOpen(true)
    disableScroll()
  }
  useEffect(() => {
    const getFormData = async () => {
      // const data = await getGfFormById(themeOptions?.footerForm)
      // setFormData(data)
    }
    if (themeOptions?.footerForm) getFormData()
  }, [themeOptions])
  const generateMenu = (menu) => {
    return menu.map((item, index) => {
      const children =
        item.children && item.children.length > 0 ? item.children : ''
      return (
        <div key={index} className={styles.section}>
          {children ? (
            <>
              <div className={styles.headline}>{item.label}</div>
              <div className={styles.col}>
                {children.map((child, indexChild) => {
                  return (
                    <Link href={child.path} key={indexChild} passHref>
                      {child.MenuACF.hasArrow ? (
                        <Button
                          className={styles.button}
                          text={child.label}
                          type="white"
                        />
                      ) : (
                        <a
                          target={child.target ? child.target : '_self'}
                          className={styles.link}
                          key={indexChild}
                        >
                          {child.label}
                        </a>
                      )}
                    </Link>
                  )
                })}
              </div>
            </>
          ) : (
            <Link passHref key={index} href={item.path} className={styles.link}>
              <a target={item.target ? item.target : '_self'}>{item.label}</a>
            </Link>
          )}
        </div>
      )
    })
  }

  return (
    <footer className={styles.footer}>
      <Container removeBorders>
        <div className={styles.inner}>
          <div className={styles.formWrapper}>
            {/* {formData && <GravityForm formData={formData} />} */}
          </div>
          {!!menu && (
            <nav className={styles.menu}>
              {generateMenu(menu.concat(menuSocial))}
            </nav>
          )}
        </div>

        {!!menuLegal && (
          <nav className={styles.legalWrapper}>
            <div className={styles.legalContainer}>
              {menuLegal.map((item, index) => {
                return (
                  <>
                    {item.legalMenu?.isCookies ? (
                      <a
                        key={index}
                        onClick={() => handleCookiesForm()}
                        className={styles.linkLegal}
                      >
                        {item.label}
                      </a>
                    ) : (
                      <Link href={item.path} key={index}>
                        <a
                          target={item.target ? item.target : '_self'}
                          className={styles.linkLegal}
                        >
                          {item.label}
                        </a>
                      </Link>
                    )}
                    {index !== menuLegal.length - 1 && <span>|</span>}
                  </>
                )
              })}
            </div>
            <div className={styles.copyright}>
              &copy; {new Date().getFullYear()}{' '}
              {router.locale === 'en' ? 'DHZB Foundation' : 'DHZB Stiftung'}
            </div>
          </nav>
        )}
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  // ...seoSocialPropTypes,
  siteTitle: PropTypes.string
}
