import {MenuItem} from '@/components/types/types'
import NavigationItem from '../NavigationItem/NavigationItem'

type Props = {
  menu: MenuItem[]
  menuOpen: boolean
  isSubMenu?: boolean
}
export default function NavigationMenu({menu, menuOpen, isSubMenu}: Props) {
  if (!menu || !menu?.length) {
    return null
  }
  return (
    <>
      {menu.map((item, index) => {
        return (
          <NavigationItem
            animationDelay={isSubMenu ? index * 0.2 + 0.4 : index * 0.1 + 0.2}
            item={item}
            key={index}
            menuOpen={menuOpen}
          />
        )
      })}
    </>
  )
}
