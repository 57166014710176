import {useWordPressContext} from '@/components/common/WordPressProvider'
import {MenuItem} from '@/components/types/types'
import {md} from '@/config'
import useMediaQuery from '@/functions/useMediaQuery'
import cn from 'classnames'
import Link from 'next/link'
import {useRouter} from 'next/router'
import NavigationMenu from '../NavigationMenu/NavigationMenu'
import styles from './Navigation.module.scss'

/**
 * Render the Navigation component.
 *
 * @author                           WebDevStudios
 * @param  {object}  props           Navigation props.
 * @param  {Array}   props.menu      Array of menu items.
 * @param            props.menuOpen
 * @param  {string}  props.className Optional classname for the element.
 * @return {Element}                 The Navigation component.
 */

type Props = {
  menu: MenuItem[]
  menuSocial: MenuItem[]
  menuOpen: boolean
}

export default function Navigation({menu, menuOpen, menuSocial}: Props) {
  const isMd = useMediaQuery(md)

  const router = useRouter()

  const {post}: any = useWordPressContext()

  const {locale: activeLocale} = router

  const translationExists = post.translations.length > 0

  const defaultSlug = translationExists ? post.translations[0].slug : ''

  const asPathDE = post.isFrontPage
    ? '/'
    : post.language.code === 'DE' && activeLocale?.toUpperCase() === 'DE'
    ? post.slug
    : defaultSlug

  const asPathEN = post.isFrontPage
    ? '/'
    : post.language.code === 'EN' && activeLocale?.toUpperCase() === 'EN'
    ? post.slug
    : defaultSlug

  return (
    <>
      {!!menu?.length && (
        <nav className={cn(styles.navigation, menuOpen && styles.menuOpen)}>
          <div>
            <div className={styles.languageWrapper}>
              {asPathEN && (
                <Link href={asPathEN} locale="en">
                  <div
                    className={cn(
                      styles.language,
                      activeLocale === 'en' ? styles.isActive : ''
                    )}
                  >
                    EN
                  </div>
                </Link>
              )}
              {asPathDE && (
                <Link href={asPathDE} locale="de">
                  <div
                    className={cn(
                      styles.language,
                      activeLocale === 'de' ? styles.isActive : ''
                    )}
                  >
                    DE
                  </div>
                </Link>
              )}
            </div>
            <ul className={styles.menu}>
              <NavigationMenu menu={menu} menuOpen={menuOpen} />
            </ul>
          </div>
          {menuSocial?.length > 0 && (
            <div className={styles.socialWrapper}>
              {menuSocial?.map((social) => {
                return social?.children?.map((element, key) => (
                  <Link key={key} href={element.path}>
                    <div
                      style={{
                        transitionDelay: `${
                          isMd ? key * 0.1 + 0.4 : key * 0.1 + 0.5
                        }s`
                      }}
                      className={styles.social}
                    >
                      {element.label}
                    </div>
                  </Link>
                ))
              })}
            </div>
          )}
        </nav>
      )}
    </>
  )
}
