import PropTypes from 'prop-types'

import {icons} from './icons'

/**
 * Convert icon size to px taking rem into account.
 *
 * @param  {string} size The icon size.
 * @return {number}      The size in px.
 */

/**
 * Render the Icon component.
 *
 * @param  {object}  props            The component attributes as props.
 * @param  {boolean} props.ariaHidden If aria-hidden is true.
 * @param  {string}  props.className  Icon className.
 * @param  {string}  props.icon       Icon svg.
 * @param  {string}  props.size       Icon size.
 * @param  {string}  props.style      Icon style variation.
 * @param  {string}  props.title      Icon title.
 * @param            props.onClick
 * @return {Element}                  The Icon component.
 */
export default function Icon({
  ariaHidden,
  className,
  icon,
  size,
  sizeViewbox,
  title,
  onClick
}: any) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox={`0 0  ${sizeViewbox} ${sizeViewbox}`}
      aria-hidden={ariaHidden}
      className={className}
      onClick={onClick}
    >
      {title && <title>{title}</title>}
      {icons[icon]}
    </svg>
  )
}

Icon.propTypes = {
  ariaHidden: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  sizeViewbox: PropTypes.number,
  style: PropTypes.oneOf(['fill', 'line']),
  title: PropTypes.string,
  onClick: PropTypes.func
}

Icon.defaultProps = {
  size: 40,
  sizeViewbox: 40
}
