import Button from '@/components/atoms/Button'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import {
  cookieNameFunctional,
  cookieNameStatistics,
  cookieSettings
} from '@/config'
import cn from 'classnames'
import {useContext, useEffect, useState} from 'react'
import Cookies from 'universal-cookie'
import {disableScroll} from '../../../../functions/enableDisableScroll'
import {CookiesFormContext} from '../Cookies/CookiesFormContext'
import styles from './CookieBanner.module.scss'

const CookieBanner = () => {
  const {themeOptions} = useWordPressContext()
  const cookies = new Cookies()

  const cookieValue = cookies.get(cookieNameFunctional)
  const [show, setShow] = useState(false)
  const {setIsCookiesFormOpen} = useContext(CookiesFormContext)
  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : ''
    if (url.search('cookies') == -1) {
      setShow(!cookieValue ? true : false)
    }
  })

  const setCookieBanner = (value) => {
    setShow(false)
    cookies.set(cookieNameFunctional, true, cookieSettings)

    if (value) {
      cookies.set(cookieNameStatistics, value, cookieSettings)
      // gtag('consent', 'update', {
      //   ad_storage: 'granted',
      //   analytics_storage: 'granted'
      // })
    }
  }

  const handleCookiesForm = () => {
    setIsCookiesFormOpen(true)
    disableScroll()
  }

  return (
    <div
      className={cn(styles.cookieBanner, show ? styles.fadeIn : styles.fadeOut)}
    >
      <div className={styles.inner}>
        <div className={styles.title}>{themeOptions?.cookieBannerTitle}</div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: themeOptions?.cookieBannerDescription
          }}
        />
        <div className={styles.row}>
          {themeOptions?.acceptLabel && (
            <Button
              className={styles.button}
              onClick={() => setCookieBanner('true')}
              text={themeOptions?.acceptLabel}
              type="filled"
              icon={null}
              isSmall
            />
          )}
          <Button
            className={styles.button}
            onClick={() => setCookieBanner('false')}
            text={themeOptions?.rejectLabel}
            type="outline"
            icon={null}
            isSmall
          />
          <div className={styles.settings} onClick={() => handleCookiesForm()}>
            <a> {themeOptions?.cookiesLabelSettings}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieBanner
