import PropTypes from 'prop-types'

/**
 *
 * @param  props0
 * @param  props0.menuOpen
 * @param  props0.setMenuOpen
 */
export default function MenuToggle({menuOpen, setMenuOpen}) {
  return (
    <div data-aos="fade-in">
      <button
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        className={`hamburger hamburger--squeeze ${
          menuOpen ? 'is-active' : ''
        }`}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        type="button"
      >
        <div className="hamburger-box">
          <div className="hamburger-inner"></div>
        </div>
      </button>
    </div>
  )
}

MenuToggle.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired
}
