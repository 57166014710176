import Icon from '@/components/atoms/Icon'
import styles from './Loading.module.scss'

export default function Loading() {
  return (
    <div className={styles.loader}>
      <div className={styles.spinner}>
        <Icon icon="spinner" size={120} sizeViewbox={120} />
      </div>
    </div>
  )
}
