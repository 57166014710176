export const md = 768

export const lg = 1133

export const cookieNameStatistics = 'allowStatistics'
export const cookieNameFunctional = 'allowFunctional'

export const cookieSettings = {
  path: '/',
  expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
}

export const bannerSettings = {
  path: '/',
  expires: new Date(new Date().setDate(new Date().getDate() + 7))
}
